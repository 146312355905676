import React from 'react';
import Navbar from "../../components/welcome/Navbar";
import houses from "../../assets/images/houses.webp"

function Welcome() {
    return (
        <div className="w-full h-screen bg-no-repeat bg-cover" style={{backgroundImage: `url(${houses})`}}>

            <div className="w-full h-screen bg-black/30">

                <Navbar/>

                <div className="flex justify-end items-center lg:mt-20">

                    <div className="w-1/2">

                        <h1 className="text-5xl text-start text-white font-bold">
                            Let's start by <br/>registering your <span className="text-orange-500">community</span>.
                        </h1>

                        <div className="mr-10">
                            <input type="text" className="flex justify-start w-full bg-white px-5 py-3 rounded-lg outline-none my-5" placeholder="Community name"/>
                        </div>

                        <button className="flex justify-start my-5 text-white bg-orange-500 hover:bg-orange-400 px-10 py-2 rounded-lg">
                            Next
                        </button>

                    </div>

                </div>

            </div>
        </div>
    );
}
export default Welcome;