import React from 'react';
import './App.css';

import {Route, BrowserRouter, Routes} from 'react-router-dom'

import NotFound from "./pages/errors/NotFound";
import Welcome from "./pages/welcome/Welcome";


function App() {

    return (
        <div className="App">

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Welcome />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
