import React from 'react';
import logoColored from '../../assets/icons/logoColored.svg';

function Navbar() {

    return (
        <header>
            <div>
                <nav className="container mx-auto sm:py-6 sm:px-7 py-5 px-4">
                    <div className="flex justify-between ">
                        <div className=" flex space-x-3 items-center w-36">
                            <img src={logoColored} alt="Owners Tally Logo" />
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;