import React from 'react';
import { useNavigate} from "react-router-dom";
import Lottie from "lottie-react";

import not_found from '../../assets/anim/not_found.json'

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="bg-white h-screen">
            <div className="flex items-center justify-center py-12">
                <div className="flex items-center justify-center mx-4 md:w-2/3">
                    <div className="flex flex-col items-center py-16 ">
                        <Lottie className="w-52" animationData={not_found} loop={true} />
                        <h1 className="px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800">UPS!</h1>
                        <p className="px-4 pb-10 text-base leading-none text-center text-gray-600">No se encuentra la página solicitada</p>
                        <button onClick={ () => navigate('/')} className="mx-4 h-10 w-44 rounded-full text-white text-base bg-amber-400 hover:bg-amber-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-amber-400">Regresar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default NotFound;
